type ResolveFunction = () => void

export class Semaphore {
  private available: number = 1
  private resolverQueue: ResolveFunction[] = []

  public wait(): Promise<void> | void {
    if (this.available > 0) {
      this.available -= 1
      return
    }

    return new Promise<void>((resolve) => {
      this.resolverQueue.push(resolve)
    })
  }

  public release(): void {
    this.available += 1

    if (this.available >= 1 && this.resolverQueue.length > 0) {
      this.available -= 1

      const nextResolver = this.resolverQueue.pop()
      if (nextResolver) {
        nextResolver()
      }
    }
  }
}
