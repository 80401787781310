import React, { useMemo } from 'react'
import { AuthSettings, AuthSettingsProvider } from './auth-settings-context'
import { ChildrenProp } from './types/children-prop'

type AuthProviderProps = AuthSettings & ChildrenProp

export const AuthProvider: React.FC<AuthProviderProps> = ({
  authority,
  clientId,
  redirectUri,
  postLogoutRedirectUri,
  scope,
  audience,
  cacheStrategy,
  children,
}) => {
  const authSettings = useMemo<AuthSettings>(() => {
    return {
      authority,
      clientId,
      redirectUri,
      postLogoutRedirectUri,
      scope,
      audience,
      cacheStrategy,
    }
  }, [cacheStrategy, clientId, authority, redirectUri, scope])

  return (
    <AuthSettingsProvider settings={authSettings}>
      {children}
    </AuthSettingsProvider>
  )
}
