import { useCallback } from 'react'
import { AuthSettings, User } from './auth-settings-context'
import { AuthTokens, parseJwt } from './auth-utils'
import { useAuthCacheStorage } from './cache-storage'

export interface AuthCache {
  accessTokens: {
    [scope: string]: {
      token: string
      expireTime: number // unix epoch in seconds
    }
  }
  idToken: string
  refreshToken: string
  user: User
}

export const useAuthCache = (
  settingsOverride: AuthSettings | null
): {
  getAuthCache: () => AuthCache | null
  setAuthTokens: (tokens: AuthTokens) => void
} => {
  const { setAuthCache: storeAuthCache, getAuthCache } =
    useAuthCacheStorage(settingsOverride)

  const setAuthTokens = useCallback(
    ({ accessToken, idToken, refreshToken, scope, expiresIn }: AuthTokens) => {
      const idClaims = parseJwt(idToken)

      if (!idClaims) {
        console.error('The id token could not be parsed.')
        return
      }

      const existingAccessTokens = getAuthCache()?.accessTokens || {}

      const currentTimestamp = Math.floor(new Date().getTime() / 1000)

      const authCache: AuthCache = {
        accessTokens: {
          ...existingAccessTokens,
          [scope]: {
            token: accessToken,
            expireTime: currentTimestamp + expiresIn,
          },
        },
        idToken,
        refreshToken,
        user: idClaims,
      }

      storeAuthCache(authCache)
    },
    [storeAuthCache, getAuthCache]
  )

  return {
    getAuthCache,
    setAuthTokens,
  }
}
