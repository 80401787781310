import { useCallback, useEffect, useState } from 'react'
import { AuthSettings, useAuthSettings } from './auth-settings-context'
import { redeemToken } from './auth-utils'
import { useAuthCacheStorage } from './cache-storage'
import { useAuthCache } from './use-auth-cache'

export interface AuthRequestState {
  state: string
  codeVerifier: string
  loginSuccessRedirectUri: string
}

export interface AuthCallbackError {
  error: string
  errorDescription: string
  retry: boolean
}

type Options = {
  settingsOverride?: AuthSettings
}

export const useAuthCallback = (options?: Options) => {
  const authSettings = useAuthSettings(options?.settingsOverride ?? null)
  const { setAuthTokens } = useAuthCache(options?.settingsOverride ?? null)
  const { getAuthRequestState, clearAuthRequestState } = useAuthCacheStorage(
    options?.settingsOverride ?? null
  )
  const [callbackError, setCallbackError] = useState<AuthCallbackError | null>(
    null
  )

  const logAndSetCallbackError = useCallback(
    (error: AuthCallbackError) => {
      console.error('Auth callback error', error)
      setCallbackError(error)
    },
    [setCallbackError]
  )

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    const code: string | undefined = params.code
    const state: string | undefined = params.state
    const error: string | undefined = params.error
    const errorDescription: string | undefined = params.error_description

    if (error && errorDescription) {
      logAndSetCallbackError({
        error: error,
        errorDescription: errorDescription,
        retry: false,
      })

      return
    }

    if (!code) {
      logAndSetCallbackError({
        error: 'code_missing',
        errorDescription: 'The authorization code was not found.',
        retry: true,
      })

      return
    }

    if (!state) {
      logAndSetCallbackError({
        error: 'state_missing',
        errorDescription: 'No state was found.',
        retry: true,
      })
    }

    const requestState = getAuthRequestState<AuthRequestState>()

    if (!requestState) {
      logAndSetCallbackError({
        error: 'Missing State',
        errorDescription:
          'No authentication request state found in the auth cache',
        retry: true,
      })

      return
    }

    if (state !== requestState.state) {
      logAndSetCallbackError({
        error: 'Invalid State',
        errorDescription:
          'State received does not match the previously generated state.',
        retry: true,
      })
      return
    }

    ;(async () => {
      const tokens = await redeemToken({
        authCode: code,
        codeVerifier: requestState.codeVerifier,
        settings: authSettings,
      })

      setAuthTokens(tokens)
      clearAuthRequestState()
      window.location.replace(requestState.loginSuccessRedirectUri)
    })()
  }, [
    authSettings,
    setAuthTokens,
    getAuthRequestState,
    clearAuthRequestState,
    setCallbackError,
  ])

  return callbackError
}
